<template>
  <el-date-picker
    v-model="timerRange"
    :clearable="false"
    type="datetimerange"
    @change="timeChange"
    value-format="timestamp"
    range-separator="-"
    size="small"
    align="center"
  >
  </el-date-picker>
</template>

<script>
import { cloneDeep } from "lodash";

export default {
  props: {
    time: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: this.$t("unit.time.fifteen"),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 0.25);
              picker.$emit("pick", [start.getTime(), end.getTime()]);
            }
          },

          {
            text: this.$t("unit.time.thirty"),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 0.5);
              picker.$emit("pick", [start.getTime(), end.getTime()]);
            }
          },

          {
            text: this.$t("unit.time.oneHour"),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 1);
              picker.$emit("pick", [start.getTime(), end.getTime()]);
            }
          },

          {
            text: this.$t("unit.time.twoHour"),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 2);
              picker.$emit("pick", [start.getTime(), end.getTime()]);
            }
          },

          {
            text: this.$t("unit.time.fiveHour"),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 5);
              picker.$emit("pick", [start.getTime(), end.getTime()]);
            }
          },

          {
            text: this.$t("unit.time.twelveHour"),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 12);
              picker.$emit("pick", [start.getTime(), end.getTime()]);
            }
          },

          {
            text: this.$t("unit.time.oneDay"),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
              picker.$emit("pick", [start.getTime(), end.getTime()]);
            }
          },
          {
            text: this.$t("unit.time.threeDay"),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 3);
              picker.$emit("pick", [start.getTime(), end.getTime()]);
            }
          }
        ]
      }
    };
  },

  computed: {
    timerRange: {
      get() {
        return cloneDeep(this.time);
      },

      set(val) {
        this.$emit(`update:time`, val);
      }
    }
  },

  methods: {
    timeChange() {
      this.$emit(`change`);
    }
  }
};
</script>
