import http from "../http";

export const auditList = params => {
  return http({
    method: "get",
    url: `/manage.kubestar.io/audits`,
    params
  });
};

export const searchColumns = params => {
  return http({
    method: "get",
    url: `/manage.kubestar.io/audits/options`,
    params
  });
};
