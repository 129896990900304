<template>
  <div>
    <div class="list-title">
      {{ $t("auditList") }}
    </div>
    <div v-loading="loading">
      <div>
        <el-select v-model="searchType" style="width: 120px" size="small" @change="searchColumns">
          <el-option :value="item.value" v-for="item in typeList" :key="item.value" :label="item.label"></el-option>
        </el-select>

        <el-select
          v-model="searchText"
          style="width: 200px; margin: 0 10px;"
          filterable
          size="small"
          @change="resultChange"
          :loading="columnLoading"
        >
          <el-option :value="item" v-for="item in resultList" :key="item" :label="item"></el-option>
        </el-select>

        <date-picker :time.sync="timeRange" @change="timeChange"></date-picker>
      </div>

      <div style="margin: 20px 0;">
        <p class="search-list-title ">{{ $t("auditSearchList") }}：</p>

        <el-tag
          :key="item.key"
          v-for="(item, index) in searchList"
          :closable="!sysAdminFlag && item.key == 'userName' ? false : true"
          :disable-transitions="false"
          size="small"
          @close="labelClose(index)"
          style="margin-right: 8px;"
        >
          {{ item.key }}:{{ item.value }}
        </el-tag>

        <span v-if="searchList.length == 0">-</span>
      </div>

      <el-table :data="data">
        <el-table-column type="expand" width="50">
          <template slot-scope="props">
            <el-row :gutter="20">
              <el-col :span="12">
                <div class="audit-req-title">
                  {{ $t("auditReqHeader") }}
                </div>
                <editor
                  v-model="props.row.reqHeader"
                  @init="editorInit"
                  lang="json"
                  theme="cobalt"
                  width="100%"
                  height="400"
                >
                </editor>
              </el-col>
              <el-col :span="12">
                <div class="audit-req-title">
                  {{ $t("auditReqBody") }}
                </div>
                <editor
                  v-model="props.row.reqBody"
                  @init="editorInit"
                  lang="json"
                  theme="cobalt"
                  width="100%"
                  height="400"
                >
                </editor>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column :label="$t('auditHandleTime')" prop="createdAt">
          <template slot-scope="scope">
            {{ moment(scope.row.createdAt).format("YYYY-MM-DD HH:mm:ss ") }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('auditUserName')" prop="userName"> </el-table-column>
        <el-table-column :label="$t('auditResource')" prop="resource"> </el-table-column>
        <el-table-column :label="$t('auditReqURI')" prop="requestURI" width="300"> </el-table-column>
        <el-table-column :label="$t('auditReqMethod')" prop="reqMethod"> </el-table-column>
        <el-table-column :label="$t('auditRespStatusCode')" prop="respStatusCode"> </el-table-column>
        <el-table-column :label="$t('auditBusinessStatusCode')" prop="businessStatusCode"> </el-table-column>
        <el-table-column :label="$t('auditBusinessMessage')" prop="businessMessage"> </el-table-column>
      </el-table>

      <el-pagination
        layout=" prev, pager, next, sizes, total"
        :total="total"
        :page-size="currentPageSize"
        :page-sizes="[10, 20, 50, 100]"
        :current-page="currentPage"
        @current-change="currentChange"
        @size-change="sizeChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { auditList, searchColumns } from "api/audit";
import DatePicker from "@/components/DatePicker";
import { includes } from "lodash";
import moment from "moment";

export default {
  components: {
    editor: require("vue2-ace-editor"),
    DatePicker
  },

  data() {
    return {
      data: [],
      total: undefined,
      loading: false,
      currentPage: 1,
      currentPageSize: 10,

      timeRange: [new Date().getTime() - 3600 * 1000 * 24 * 1, new Date().getTime()],

      searchType: "",
      searchText: "",
      searchList: [],
      columnLoading: true,

      typeList: [],

      resultList: []
    };
  },

  methods: {
    moment,

    getList(page = 1, pageSize = this.currentPageSize, startTime = this.timeRange[0], endTime = this.timeRange[1]) {
      this.loading = true;
      this.currentPage = page;

      let searchList = {};
      this.searchList.forEach(item => {
        searchList[item.key] = item.value;
      });

      auditList({ page, pageSize, startTime, endTime, ...searchList }).then(response => {
        this.loading = false;

        if (response.code === 0) {
          this.total = response.data.total;
          this.data = response.data.items;

          this.data.forEach(item => {
            item.reqHeader = JSON.stringify(JSON.parse(item.reqHeader), null, 2);
            if (item.reqBody) item.reqBody = JSON.stringify(JSON.parse(item.reqBody), null, 2);
          });
        }
      });
    },

    editorInit() {
      require("brace/ext/searchbox");
      require("brace/mode/json");
      require("brace/theme/cobalt");
    },

    currentChange(page) {
      this.currentPage = page;
      this.getList(page);
    },

    sizeChange(size) {
      this.currentPageSize = size;
      this.getList(1, size);
    },

    timeChange() {
      this.getList(1);
    },

    labelClose(index) {
      this.searchText = "";
      this.searchList.splice(index, 1);
      this.getList(1);
    },

    resultChange() {
      if (this.searchText) {
        let searchList = this.searchList.map(item => item.key);

        if (includes(searchList, this.searchType)) {
          this.searchList.forEach((item, index) => {
            if (item.key === this.searchType) {
              this.searchList.splice(index, 1, {
                key: this.searchType,
                value: this.searchText
              });
            }
          });
        } else {
          this.searchList.push({
            key: this.searchType,
            value: this.searchText
          });
        }

        this.getList(1);
      }
    },

    searchColumns() {
      this.columnLoading = true;
      this.searchText = "";
      searchColumns({ column: this.searchType }).then(response => {
        if (response.code === 0) {
          this.columnLoading = false;
          this.resultList = response.data;
        }
      });
    },

    init() {
      this.typeList = [
        { label: this.$t("auditReqMethod"), value: "req_method" },
        { label: this.$t("auditResource"), value: "resource" }
      ];

      this.searchType = "req_method";
      this.searchList = [{ key: "user_name", value: this.userName }];

      if (this.sysAdminFlag) {
        this.typeList.unshift({ label: this.$t("auditUserName"), value: "user_name" });
        this.searchType = "user_name";
        this.searchList = [];
      }
    }
  },

  computed: {
    userName() {
      return this.$store.state.app.userName;
    },

    sysAdminFlag() {
      return this.$store.state.app.userInfo.sysAdminFlag;
    }
  },

  mounted() {
    this.init();
    this.getList();
    this.searchColumns();
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.audit-req-title {
  @include title(14px);
  margin-bottom: 10px;
}

.search-list-title {
  display: inline-block;
  @include title(12px);
}
</style>
